<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader :title="id ? 'Update Question' : 'Create new Question'" />
        <div>
          <md-button :to="{ name: 'Faq' }" class="md-primary"> Back </md-button>
        </div>
      </div>

      <div>
        <form novalidate @submit.prevent="validateCommission">
          <div class="">
            <section class="row my-3" style="width: 100%">
              <div class="col-md-8">
                <md-field :class="getValidationClass('question')">
                  <label>Question</label>
                  <md-input
                    placeholder="Enter Question"
                    v-model="form.question"
                    required
                  ></md-input>
                  <span class="md-error" v-if="!$v.form.question.required"
                    >Question is required</span
                  >
                </md-field>

                <md-field>
                  <label for="movie">Faq Category</label>
                  <md-select
                    v-model="form.category_id"
                    name="section_type"
                    id="section_type"
                  >
                    <md-option
                      v-for="category in formData.categories"
                      :key="category.id"
                      :value="category.id"
                      >{{ category.title }}</md-option
                    >
                  </md-select>
                </md-field>

                <md-field :class="getValidationClass('answer')">
                  <label>Answer</label>
                  <md-textarea v-model="form.answer" required></md-textarea>
                  <span class="md-error" v-if="!$v.form.answer.required"
                    >Answer is required</span
                  >
                </md-field>

                <div style="width: 100%">
                  <md-checkbox v-model="form.status">Visibility </md-checkbox>
                </div>
              </div>
            </section>
          </div>
          <mdc-button :loading="loading">{{
            id ? "Update" : "Create"
          }}</mdc-button>
        </form>
      </div>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("faq");

export default {
  mixins: [validationMixin],
  data: () => ({
    form: {},
    loading: false,
    search: "",
    formData: {
      categories: [],
    },
    categories: [],
    fetching: true,
  }),
  validations: {
    form: {
      question: {
        required,
      },
      answer: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(["createFaq", "updateFaq", "getFormData", "getFaq"]),
    async validateCommission() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = { ...this.form };
        data.status = data.status ? "live" : "draft";

        try {
          this.loading = true;
          let call = this.id ? this.updateFaq(data) : this.createFaq(data);
          let msg = this.id ? "FAQ Updated" : "FAQ Created";
          await call;
          this.toast("success", msg);
          this.$router.push({ name: "Faq" });
          this.loading = false;
        } catch (err) {
          console.log(err);
          this.loading = false;
          this.toast("error", "Opps, Something went wrong ");
        }
      }
    },
    setUser(user) {
      this.isOpen = false;
      this.user = user;
    },
    async getData() {
      this.fetching = true;
      const res = await this.getFormData();
      console.log(res);
      this.fetching = false;
      this.formData = res;
    },

    async getFaqData() {
      if (this.id) {
        this.fetching = true;
        const data = await this.getFaq(this.id);

        this.form = {
          id: data.id,
          question: data.question,
          answer: data.answer,
          category_id: data.category_id,
          status: data.status == "live" ? true : false,
        };
        this.fetching = false;
      }
    },
  },
  created() {
    // this.getCommissionData();
  },
  mounted() {
    this.getFaqData();
    this.getData();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped lang="scss">
.feature-attributes {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .val {
      flex: 1;
    }

    .attr {
      width: 25%;
    }
  }
}

.s-title {
  margin-bottom: 20px;
  strong {
    font-weight: 700;
  }
}

.image-box {
  height: 130px;
  width: 100%;
  border: 2px dashed #d2d2d2;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }

  .preview {
    height: 100%;
    width: 100%;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

.users-search {
  position: relative;

  .users-card {
    width: 100%;
    background: #fff;
    max-height: 300px;
    overflow-y: auto;

    .items {
      padding: 10px;
      cursor: pointer;

      &:hover {
        background: #f9f9f9;
      }
    }
  }
  .selected {
    padding: 15px 10px;
    background: #e3ffe6;
    border-radius: 5px;
    font-weight: 700;
  }
}
</style>
